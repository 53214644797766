



























import { Vue, Component } from "vue-property-decorator";
import SocialSignInButtons from "@/components/SocialSignInButtons.vue";
import BackButton from "@/components/BackButton.vue";
import { Getter } from "vuex-class";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { isWebView } from "@/plugins/utils";

@Component({
    components: { BackButton, SocialSignInButtons },
})
export default class AuthSignIn extends Vue {
    afterAuthPath: string | null = null;

    @Getter("isLoggedIn") isLoggedIn!: boolean;
    @Getter("previousPath") previousPath!: string | null;

    get isWebView() {
        return isWebView();
    }

    mounted() {
        if (this.isLoggedIn) {
            addAnalyticsEvent("user_already_logged_in", {
                path: this.previousPath,
            });
            this.$router.push({
                name: this.$constants.ROUTE_NAMES.DSTV_INDEX,
            });
        }

        if (this.$route.query["error"]) {
            setTimeout(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.ERROR,
                    this.$route.query["error"]
                );
            }, 1000);
        }

        if (this.$route.query["info"]) {
            setTimeout(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.INFO,
                    this.$route.query["info"]
                );
            }, 1000);
        }

        if (this.$route.query["success"]) {
            setTimeout(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    this.$route.query["success"]
                );
            }, 1000);
        }

        if (this.$route.query["afterAuthPath"]) {
            this.afterAuthPath = this.$route.query["afterAuthPath"] as string;
        }
    }
}
